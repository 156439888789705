import * as React from "react";
import HcpLayout from "../../components/hcp/layout";
import { Row, Col } from "react-grid-system";
import { Seo } from "../../components/seo";
import useDeviceSize from "../../hooks/use-device-size";

import "../../sass/_orphan.scss";
import "./discussion-guide.scss";

import bannerImg from "../../images/orphan/kristen-hcp-header_desktop.webp";
import bannerImgMobile from "../../images/orphan/kristen-hcp-header_mobile.webp";
import pdfThumb from "../../images/orphan/thumb-pdf-design-discussion.webp";

const DiscussionGuidePage = () => {
    const deviceSizes = useDeviceSize();
    return (
        <HcpLayout
            pageClass="discussion-guide-page orphan-page"
            jobCode="US-WAK-2300495/Dec 2023"
            isGhostPage="true"
        >
            <div className="hero-wrapper row-full">
                <div className="hero-content-wrapper">
                    <div className="hero-banner inner bg-cover">
                        {deviceSizes?.smUp ? (
                            <img
                                src={bannerImg}
                                className="banner-mobile"
                                alt="Talking with your healthcare provider"
                            />
                        ) : (
                            <img
                                src={bannerImgMobile}
                                className="banner-desktop"
                                alt="Talking with your healthcare provider"
                            />
                        )}
                        {/* <div className="banner-text show-desktop">
                <div className="centered-content">
                  <h1 className="h1-lrg">Talking With Your <br className="show-desktop"/>Healthcare Provider</h1>
                </div>
              </div> */}
                    </div>
                </div>
            </div>
            <section>
                <div className="centered-content">
                    <div className="page-heading ph-btm">
                        <h1 className="font-40">
                            Talking With Your Healthcare Provider
                        </h1>
                    </div>
                </div>
            </section>
            <section className="gray-bg cta-block">
                <div className="centered-content">
                    <Row className="discussion-row">
                        <Col xs={12} md={9} className="discussion-col">
                            <div className="content-copy">
                                <h2>Design Your Discussion</h2>
                                <p className="top-spacer btm-spacer">
                                    Being an active member of your healthcare
                                    team can be key to having your needs and
                                    preferences considered when it comes to your
                                    treatment plan. Use this guide to help you
                                    prepare for your appointment so that you are
                                    ready to work with your healthcare provider
                                    to get closer to your treatment goals.
                                </p>
                                <div className="btn-wrap">
                                    <a
                                        href="/pdf/WAKIX-doctor-discussion-guide.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn btn-primary btn-primary--md dwnload dwn-btn"
                                    >
                                        Download the Discussion Guide
                                        <svg
                                            id="download_arrow"
                                            data-name="download arrow"
                                            xmlns="http://www.w3.org/2000/svg"
                                            xlink="http://www.w3.org/1999/xlink"
                                            width="21"
                                            height="22"
                                            viewBox="0 0 21 22"
                                        >
                                            <defs>
                                                <clipPath id="clip-path">
                                                    <path
                                                        id="Clip_4"
                                                        data-name="Clip 4"
                                                        d="M0,0H2V16H0Z"
                                                        fill="none"
                                                    />
                                                </clipPath>
                                            </defs>
                                            <path
                                                id="Fill_1"
                                                data-name="Fill 1"
                                                d="M19.99,5H1.01A.971.971,0,0,1,0,4.073V.927A.971.971,0,0,1,1.01,0a.971.971,0,0,1,1.01.927V3.145H18.981V.927A1.013,1.013,0,0,1,21,.927V4.073A.971.971,0,0,1,19.99,5"
                                                transform="translate(0 17)"
                                                fill="#fff"
                                            />
                                            <g
                                                id="Group_5"
                                                data-name="Group 5"
                                                transform="translate(9)"
                                            >
                                                <path
                                                    id="Clip_4-2"
                                                    data-name="Clip 4"
                                                    d="M0,0H2V16H0Z"
                                                    fill="none"
                                                />
                                                <g
                                                    id="Group_5-2"
                                                    data-name="Group 5"
                                                    clipPath="url(#clip-path)"
                                                >
                                                    <path
                                                        id="Fill_3"
                                                        data-name="Fill 3"
                                                        d="M1,16a1.016,1.016,0,0,1-1-1.032V1.032A1.016,1.016,0,0,1,1,0,1.016,1.016,0,0,1,2,1.032V14.968A1.016,1.016,0,0,1,1,16"
                                                        fill="#fff"
                                                    />
                                                </g>
                                            </g>
                                            <path
                                                id="Fill_6"
                                                data-name="Fill 6"
                                                d="M6.976,8a1.072,1.072,0,0,1-.754-.309L.312,1.847a1.046,1.046,0,0,1,0-1.49,1.074,1.074,0,0,1,1.507,0l5.156,5.1,5.2-5.147a1.074,1.074,0,0,1,1.507,0,1.046,1.046,0,0,1,0,1.49L7.729,7.691A1.072,1.072,0,0,1,6.976,8"
                                                transform="translate(3 9)"
                                                fill="#fff"
                                            />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col
                            xs={12}
                            md={3}
                            className="discussion-col discussion-col-2"
                        >
                            <img
                                src={pdfThumb}
                                alt="Design your discussion thumbnail"
                                className="box-shadow"
                            />
                        </Col>
                    </Row>
                </div>
            </section>
        </HcpLayout>
    );
};

export default DiscussionGuidePage;

export const Head = () => (
    <Seo
        title="Talking With Your Healthcare Provider | WAKIX® (pitolisant) tablets"
        description="Use this discussion guide to help prepare for your appointment with your healthcare provider."
        keywords=""
    />
);
